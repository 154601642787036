import { isEmpty } from "lodash";
import Vue from "vue";

export default Vue.extend({
    name: "LogicDialogNotes", 
    props: {
        title: {
            type:String,
            required:true,
        },
        noteHistory: {
			type: Array,
			default: [],
		},
        loadingNotes:{
            type: Boolean,
            default: false
        }
    },
    components:{},
    data: () => ({
        messages: [],

    }),
    created(){},
    mounted(){},
    computed:{
        showMsgEmpty(){
            return isEmpty(this.messages);
        },

        getTextLoading(){
            return this.$t('loading');;
        },

        isLoadingNote(){
            return this.loadingNotes
        }
    },
    methods:{

        closeDialog(){
            this.$emit("close", {modal: false, fetch: false});
        },

        async loadMessages(){
            this.messages = [];
            this.noteHistory.forEach((m:any) => {
                let obj = {
                    id: m.id,
                    message: m.note,
                    time: m.date_note,
                }
                this.messages.push(obj);
            });
        },

        async loadHistory(){
            this.$emit("update-page-note");
            this.$emit("fetch-history-note");
        },
    },
    watch: {
        "noteHistory"(){
            this.loadMessages();
        }
    }
});