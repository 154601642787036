import Vue from "vue";
// @ts-ignore
import { isEmpty, isNaN } from "lodash";
// @ts-ignore
import { Paginated } from "@/interfaces/paginated";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import ParamService from "@/services/params-service";
// @ts-ignore
import { parseData } from './Utils';
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
    name: "LogicTableCreative",
    props:{
        campaign:{
            type: Object,
            default: function(){
                return {};
            }
        },
        canEdit:{
            type: Boolean,
            default:true
        }
    },
    components:{ },
    data: () => ({
        paginated: { page: 1, limit: 25 } as Paginated,
		filters: {},
		options: getDefaultSortingOption("id"),
        headers: [],
        items: [],
        lstImg: [],
        current_page: 0,
		next_page_url: "",
		path: "", 
		per_page: 25,
		prev_page_url: "",
		to: 0,
		total: 0
    }),
    created(){},
    mounted(){
        this.$nextTick(async () => {
            await this.loadResources();
            await this.loadData();
        });
    },
    computed:{
        ...mapGetters("profile", ["getAbility"]),
        getLength() {
			return Math.ceil(this.total / this.per_page);
		},

        perPage() {
			return !isNaN(this.per_page) && isEmpty(this.per_page)
				? this.per_page
				: 25;
		},

        getIdRequestCampaign(){
            return this.campaign.id;
        }
    },
    methods:{
		...mapActions("loading", ["setLoadingData"]),

        ...mapActions("request_campaign", ["paginatedCreative"]),

        updatePaginate(data: any) {
			this.paginated.page = data;
		},

        changePage(data: Number) {
			this.updatePaginate(data);
		},

        setEditCreative(item: any){
            this.$emit("set-edit-creative", item);
        },

        getItemsImg(item: any){
            if(isEmpty(this.lstImg)) return "https://picsum.photos/id/11/500/300";
            let source = this.lstImg.find(i => i.id == item.id);
            let values = new Array();
            source.sources.forEach(im => {
                values.push({src: im.file_url});
            });
            if(isEmpty(values)){
                return "https://picsum.photos/id/11/500/300";
            }else{
                return values[0].src;
            }
        },

        async getPaginated() {
			await this.setLoadingData(TypeLoading.loading);
			const response = await this.paginatedCreative(
				await ParamService.getParams(
					this.paginated,
					this.filters,
					this.options
				)
			);
			await this.setLoadingData();
            return response;
		},

        async loadResources(){
            this.headers = await this.preparedHeaderTable();
            this.filters["campaign_id"] = String(this.getIdRequestCampaign);
        },

        async loadData(){
            await this.getPaginated()
            .then((result) => {
                this.current_page = Number(result.current_page);
                this.next_page_url = String(result.next_page_url);
                this.path = String(result.path);
                this.per_page = Number(result.per_page);
                this.prev_page_url = String(result.prev_page_url);
                this.to = Number(result.to);
                this.total = Number(result.total);
                this.items = result.data;
            });
            await this.loadImg();
        }, 

        async preparedHeaderTable(){
            return [
                {
                    text: "Creative Id",
                    align: "center",
                    sortable: false,
                    filterable: false,
                    value: "id",
                },
                {
                    text: "Thumbnail",
                    align: "center",
                    sortable: false,
                    filterable: false,
                    value: "thumbnail",
                },
                {
                    text: "Creative Name",
                    align: "center",
                    sortable: false,
                    filterable: false,
                    value: "creative_name",
                },
                {
                    text: "Creative Type",
                    align: "center",
                    sortable: false,
                    filterable: false,
                    value: "type_creative",
                },
                {
                    text: "",
                    align: "center",
                    sortable: false,
                    value: "actions",
                    width: "10%",
                },
            ];
        },

        async preparedImg(typeCreative: String, item: any){
            let response: any;
            switch(typeCreative){
                case "Display":
                    response = await parseData(item.creative_imagen_display);
                    break;
                case "Native":
                    response = await parseData(item.creative_image_native);
                    break;
                case "Video":
                    response = await parseData(item.creative_end_card_image);
                    break;
            }
            return response;
        },

        async loadImg(){
            if(!isEmpty(this.items)){
                this.lstImg = [];
                this.items.forEach(async (i: any) => {
                    let dataSource = await this.preparedImg(i.type_creative, i);
                    if(Array.isArray(dataSource)){
                        this.lstImg.push({id: i.id, sources: dataSource});
                    }else{
                        let values = new Array();
                        values.push(dataSource);
                        this.lstImg.push({id: i.id, sources: values});
                    }
                });
            }
        }

    },
    watch: {
		"paginated.page"(val, old) {
			if (val !== old) {
				this.loadData();
			}
		}
	}
});