import Vue from "vue";
// @ts-ignore
import FormCreative from "@/views/Admin/RequestCampaign/Dependencies/Creatives/FormCreative.vue";
// @ts-ignore
import TableCreative from "@/views/Admin/RequestCampaign/Dependencies/Creatives/TableCreative.vue";
//@ts-ignore
import { getPermisionCampaignCpi } from "@/utils/permissionResolve.ts";
// @ts-ignore
import { mapGetters } from "vuex";

export default Vue.extend({
    name: "LogicAssociatedCreative",
    props:{
        campaign:{
            type: Object,
            default: function(){
                return {};
            }
        }
    },
    components:{
        FormCreative,
        TableCreative
    },
    data: () => ({
        creative: {},
        editCreative: false,
        items: [
            {
                key: 0,
                visible: true
            },
            {
                key: 1,
                visible: false
            }
        ]
    }),
    created(){},
    mounted(){},
    computed:{
        ...mapGetters("profile", ["getAbility"]),
        isFormCreativeSelected(){
            return this.items.find(i => i.key == 1).visible;
        },  

        isTableCreativeSelected(){
            return this.items.find(i => i.key == 0).visible;
        },
        
        getCampaignRequest(){
            return this.campaign;
        },

        getCreative(){
            return this.creative;
        },

        getEditCreative(){
            return this.editCreative;
        },

        canEditCreatives(){
			return this.getAbility.can(this.getPermission.actions.update_creative, this.getPermission.subject);
		},

        canCreateCreatives(){
			return this.getAbility.can(this.getPermission.actions.create_creative, this.getPermission.subject);
		},

        canListCreatives(){
			return this.getAbility.can(this.getPermission.actions.index_create, this.getPermission.subject);
		},

        getPermission(){
			return getPermisionCampaignCpi();
		}
    },
    methods:{

        setFormCreative(){
            this.items.find(i => i.key == 0).visible = false;
            this.items.find(i => i.key == 1).visible = true;
        },

        setTableCreative(){
            this.items.find(i => i.key == 0).visible = true;
            this.items.find(i => i.key == 1).visible = false;
            this.editCreative = false;
            this.creative = {};
        },

        setEditCreative(entity: any){
            this.creative = entity;
            this.editCreative = true;
            this.setFormCreative();
        }

    },
    watch:{}
});