import Vue from "vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import DatePicker from "@/components/Content/DatePicker.vue";
// @ts-ignore
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import CardTextarea from "@/components/Content/CardTextarea.vue";
// @ts-ignore
import { initCreativeRequestCampaign } from "@/utils/initData";
// @ts-ignore
import { hasFieldByTemplateIdAssociatedCreative } from "@/utils/fields";
// @ts-ignore
import { isEmpty } from "lodash";
import {
	isRequired,
    isMaxLength,
    isMax,
    isMin
	// @ts-ignore
} from "@/services/rule-services";
// @ts-ignore
import { parseData, parseArr, parseFile } from './Utils';

import { mapActions } from "vuex";

// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";

export default Vue.extend({
    name: "LogicFormCreative",
    props:{
        campaign:{
            type: Object,
            default: function(){
                return {};
            }
        },
        creativeEdit:{
            type: Object,
            default: function(){
                return {}
            }
        },
        edit:{
            type: Boolean,
            default: false
        }
    },
    components:{ 
        CardTextField,
        CardAutocomplete,
        DatePicker,
        DataPickerStarTime,
        Separator,
        CardActions,
        CardTextarea
    },
    data: () => ({
        valid: true,
        creative: initCreativeRequestCampaign(),
        errorValid: false,
        msgError: "files are missing",
        files:{
            img:{
                display: undefined,
                native: undefined,
                video: undefined
            },  
            icon: undefined,
            video: undefined
        },
        filesDelete:{
            img:{
                display: [],
                native: [],
                video: []
            },  
            icon: [],
            video: []
        },
        resources: {
            sizeList: [],
            aspectRatioList: [],
            creativeTypeList: [],
        },
        rules:{
            nameRule: [],
            sizeRule: [],
            apectRule: [],
            titleRule: [],
            bodyRule: [],
            callRule: [],
        }
    }),
    created(){},
    mounted(){
        this.$nextTick(async () => {
            await this.load();
        });
    },
    computed:{

        isEdit(){
            return this.edit
        },

        getRules() {
			return {
				isRequired,
				isMaxLength,
                isMax,
                isMin,
			};
		},

        getCreativeTemplate(){
            return this.resources.creativeTypeList;
        },

        getSizeList(){
            return this.resources.sizeList;
        },

        getAspectRatio(){
            return this.resources.aspectRatioList;
        },

        getLabelImagen(){
            switch(this.getTypeCreative(this.creative.type_creative).extra){
                case "crea_display":
                    return this.$t("requestCampaign.label.upload_text_imagen_1");
                case "crea_native":
                    return this.$t("requestCampaign.label.upload_text_imagen_2");
                case "crea_video":
                    return this.$t("requestCampaign.label.upload_text_imagen_3");
            }
        },

        getIdCampaign(){
            return this.campaign.id;
        },

        isTemplateDisplay(){
            return this.getTypeCreative(this.creative.type_creative).extra == "crea_display";
        },

        isTemplateNative(){
            return this.getTypeCreative(this.creative.type_creative).extra == "crea_native";
        },

        isTemplateVideo(){
            return this.getTypeCreative(this.creative.type_creative).extra == "crea_video";
        },

        isRequired(){
            switch(this.getTypeCreative(this.creative.type_creative).extra){
                case "crea_display":
                    return true;
                case "crea_native":
                    return true;
                case "crea_video":
                    return false;
            }
        },

    },
    methods:{
		...mapActions("loading", ["setLoadingData"]),

        ...mapActions("request_campaign", [
            "getListData",
            "createCreative",
            "updateCreative"
        ]),

        ...mapActions("proccess", [
			"setLoadingField",
            "setErrors"
        ]),

        clearRules() {
            this.rules.nameRule = [];
            this.rules.sizeRule = [];
            this.rules.apectRule = [];
            this.rules.titleRule = [];
            this.rules.bodyRule = [];
            this.rules.callRule = [];
		},

        getDefaultCreativeType(){
            return this.getCreativeTemplate.find(c => c.extra == "crea_display").id;
        },
        
        getTypeCreative(idCreative: any){
            return this.getCreativeTemplate.find(c => c.id == idCreative);
        },

        getExtensionImg(){
            return ".png, .jpeg, .gif, .jpg";
        },

        getExtensionVideo(){
            return ".mp4";
        },

        showFieldByTemplateId(input: string) {
            if(!this.creative.type_creative) return;
            if(isEmpty(this.getCreativeTemplate)) return;
			return hasFieldByTemplateIdAssociatedCreative(
				this.getTypeCreative(this.creative.type_creative).extra,
				input
			);
		},

        removeDataResources(key: string){
            this.resources[key] = [];
        },

        getHeightList(count: number){
            if(count > 5){
                return (5 * 55);
            }else{
                return count * 55;
            }
        },

        getFileImageCreative(){
            switch(this.getTypeCreative(this.creative.type_creative).extra){
                case "crea_display":
                    return isEmpty(this.creative.imagen_display) ? [] : this.creative.imagen_display;
                case "crea_native":
                    return isEmpty(this.creative.imagen_native) ? [] : this.creative.imagen_native;
                case "crea_video":
                    return isEmpty(this.creative.imagen_video) ? [] : this.creative.imagen_video;
            }
        },

        getIconsCreative(){
            return this.creative.icons;
        },

        getVideosCreative(){
            return this.creative.vid;
        },

        getThumbailVideo(){
            if(isEmpty(this.creative.imagen_video)){
                return "";
            }else{
                return this.creative.imagen_video[0].file_url;
            }
        },

        handleCancel(){
            this.$emit("set-table-active");
		},

        async addFileInCreative(){
            switch(this.getTypeCreative(this.creative.type_creative).extra){
                case "crea_display":
                    if(!this.files.img.display)return;
                    if(this.creative.imagen_display.length > 2)return;
                    let resDisplay = await parseFile(this.files.img.display);
                    this.creative.imagen_display.push(resDisplay);
                    this.files.img.display = undefined;
                    break;
                case "crea_native":
                    if(!this.files.img.native)return;
                    let resNative = await parseFile(this.files.img.native);
                    this.creative.imagen_native = [];
                    this.creative.imagen_native.push(resNative);
                    this.files.img.native = undefined;
                    break;
                case "crea_video":
                    if(!this.files.img.video)return;
                    let resVideo = await parseFile(this.files.img.video);
                    this.creative.imagen_video = [];
                    this.creative.imagen_video.push(resVideo);
                    this.files.img.video = undefined;
                    break;
            }
        },

        async addIconCreative(){
            if(!this.files.icon)return;
            let resNative = await parseFile(this.files.icon);
            this.creative.icons = [];
            this.creative.icons.push(resNative);
            this.files.icon = undefined;
        },

        async addVideoCreative(){
            if(!this.files.video)return;
            let resNative = await parseFile(this.files.video);
            this.creative.vid = [];
            this.creative.vid.push(resNative);
            this.files.video = undefined;
        },

        deleteFileImagen(item: any){
            switch(this.getTypeCreative(this.creative.type_creative).extra){
                case "crea_display":
                    let imgD = this.creative.imagen_display.find(f => f.file_name == item.file_name);
                    if(!imgD.hasOwnProperty("file")){
                        this.filesDelete.img.display.push(imgD.file_name);
                    }   
                    let fileIndexDisplay = this.creative.imagen_display.findIndex(f => f.file_name == item.file_name);
                    this.creative.imagen_display.splice(fileIndexDisplay, 1);
                    break;
                case "crea_native":
                    let imgN = this.creative.imagen_native.find(f => f.file_name == item.file_name);
                    if(!imgN.hasOwnProperty("file")){
                        this.filesDelete.img.native.push(imgN.file_name);
                    }
                    let fileIndexNative = this.creative.imagen_native.findIndex(f => f.file_name == item.file_name);
                    this.creative.imagen_native.splice(fileIndexNative, 1);
                    break;
                case "crea_video":
                    let imgV = this.creative.imagen_video.find(f => f.file_name == item.file_name);
                    if(!imgV.hasOwnProperty("file")){
                        this.filesDelete.img.video.push(imgV.file_name);
                    }
                    let fileIndexVideo = this.creative.imagen_video.findIndex(f => f.file_name == item.file_name);
                    this.creative.imagen_video.splice(fileIndexVideo, 1);
                    break;
            }
        },

        deleteFileIcon(item: any){
            let imgI = this.creative.icons.find(f => f.file_name == item.file_name);
            if(!imgI.hasOwnProperty("file")){
                this.filesDelete.icon.push(imgI.file_name);
            }
            let fileIndex = this.creative.icons.findIndex(f => f.file_name == item.file_name);
            this.creative.icons.splice(fileIndex, 1);
        },

        deleteFileVideo(item: any){
            let imgV = this.creative.vid.find(f => f.file_name == item.file_name);
            if(!imgV.hasOwnProperty("file")){
                this.filesDelete.video.push(imgV.file_name);
            }
            let fileIndex = this.creative.vid.findIndex(f => f.file_name == item.file_name);
            this.creative.vid.splice(fileIndex, 1);
        },

        configCreative(){
            this.creative.type_creative = this.getDefaultCreativeType();
        },

        async validFileDisplay(){
            if(isEmpty(this.creative.imagen_display)) return false;
            return this.creative.imagen_display.length >= 1;
        },

        async validFileNative(){
            if(isEmpty(this.creative.imagen_native)) return false;
            if(isEmpty(this.creative.icons)) return false;
            return (
                this.creative.imagen_native.length >= 1 &&
                this.creative.icons.length >= 1
            );
        },

        async validFileVideo(){
            if(isEmpty(this.creative.vid)) return false;
            return this.creative.vid.length >= 1;
        },

        async load(){
            if(this.isEdit){
                await this.setLoadingData(TypeLoading.loading);
                await this.fetchResources("creativeTypeList", "description", true);
                await this.fetchResources("sizeList", "description", false);
                await this.fetchResources("aspectRatioList", "description", false);
                await this.parseEntity(this.creativeEdit);
                await this.setLoadingData();
            }else{
                await this.setLoadingData(TypeLoading.loading);
                await this.fetchResources("creativeTypeList", "description", true)
                .then(() => {
                    this.configCreative();
                });
                await this.setLoadingData();
            }
        },

        async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

        async addRules() {
			this.rules.nameRule = [
				this.getRules.isRequired,
				this.getRules.isMaxLength
			];
            
            switch(this.getTypeCreative(this.creative.type_creative).extra){
                case "crea_display":
                    this.rulesCreativeDisplay();
                    break;
                case "crea_native":
                    this.rulesCreativeNative();
                    break;
                case "crea_video":
                    this.rulesCreativeVideo();
                    break;
            }
            
		},

        async rulesCreativeDisplay(){
            this.rules.sizeRule = [
				this.getRules.isRequired
			];
        },

        async rulesCreativeNative(){
            this.rules.titleRule = [
				this.getRules.isRequired
			];
            this.rules.bodyRule = [
				this.getRules.isRequired
			];
            this.rules.callRule = [
				this.getRules.isRequired
			];
        },

        async rulesCreativeVideo(){
            this.rules.apectRule = [
				this.getRules.isRequired
			];
        },

        async fetchResources(keyField: string, attrField: string = "name", addExtra: boolean = false){
            let params = {
                key: keyField,
                keyAttr: "id",
                attr: attrField,
                extra: addExtra
            }
            await this.setLoadingField(true);
            this.removeDataResources(keyField);
            this.resources[keyField] = await this.getListData(params);
            await this.setLoadingField(false);
        },

        async getFormDataDisplay(){
            let formData = new FormData();
            formData.append("creative_name", this.creative.name);
            formData.append("campaign_id", this.getIdCampaign);

            if(!isEmpty(this.creative.size)){
                this.creative.size.forEach((s: any) => {
                    formData.append("creative_size_id[]", s);
                });
            }

            if(!isEmpty(this.creative.imagen_display)){
                this.creative.imagen_display.forEach((img: any, index) => {
                    if(img.hasOwnProperty("file")){
                        let prefix = "file";
                        if((index + 1) >= 2){
                            prefix = "file" + String(index + 1);
                        }else{
                            prefix = "file"
                        }
                        formData.append(prefix, img.file);
                    }
                });
            }

            if(!isEmpty(this.filesDelete.img.display)){
                this.filesDelete.img.display.forEach((s: any) => {
                    formData.append("deleted_files[]", s);
                });
            }

            return formData;
        },

        async getFormDataNative(){
            let formData = new FormData();

            formData.append("creative_name", this.creative.name);
            formData.append("campaign_id", this.getIdCampaign);
            formData.append("creative_title_text", this.creative.title_text);
            formData.append("creative_body_text", this.creative.body_text);
            formData.append("creative_call_to_action", this.creative.call_to_action);

            if(!isEmpty(this.creative.icons)){
                this.creative.icons.forEach((img: any) => {
                    if(img.hasOwnProperty("file")){
                        formData.append("icon", img.file);
                    }
                });
            }

            if(!isEmpty(this.creative.imagen_native)){
                this.creative.imagen_native.forEach((img: any) => {
                    if(img.hasOwnProperty("file")){
                        formData.append("image", img.file);
                    }
                });
            }
            
            if(!isEmpty(this.filesDelete.img.native)){
                this.filesDelete.img.native.forEach((s: any) => {
                    formData.append("deleted_image", s);
                });
            }

            if(!isEmpty(this.filesDelete.native)){
                this.filesDelete.native.forEach((s: any) => {
                    formData.append("deleted_icon", s);
                });
            }

            return formData;
        },

        async getFormDataVideo(){
            let formData = new FormData();

            formData.append("creative_name", this.creative.name);
            formData.append("campaign_id", this.getIdCampaign);

            if(!isEmpty(this.creative.aspect_ratio)){
                this.creative.aspect_ratio.forEach((s: any) => {
                    formData.append("creative_aspect_ratio_id[]", String(s));
                });
            }

            if(!isEmpty(this.creative.vid)){
                this.creative.vid.forEach((img: any) => {
                    if(img.hasOwnProperty("file")){
                        formData.append("video", img.file);
                    }
                });
            }

            if(!isEmpty(this.creative.imagen_video)){
                this.creative.imagen_video.forEach((img: any) => {
                    if(img.hasOwnProperty("file")){
                        formData.append("end_card_image", img.file);
                    }
                });
            }

            if(!isEmpty(this.filesDelete.img.video)){
                this.filesDelete.img.video.forEach((s: any) => {
                    formData.append("deleted_end_card", s);
                });
            }

            if(!isEmpty(this.filesDelete.video)){
                this.filesDelete.video.forEach((s: any) => {
                    formData.append("deleted_video", s);
                });
            }

            return formData;
        },

        async handleAction(action: { type: any }) {
			switch (action.type) {
				case "save":
                    this.handleSubmit();
					break;
				case "save-continue":
					break;
				case "cancel":
					this.handleCancel();
					break;
			}
		},

        async handleSubmit(){
            try {
                await this.addRules();

				if (!(await this.validate())) return;

                switch(this.getTypeCreative(this.creative.type_creative).extra){
                    case "crea_display":
                        if(!await this.validFileDisplay()) { this.errorValid = true; return; };
                        this.errorValid = false;
                        let formDisplay = await this.getFormDataDisplay();
                        await this.submitCreative(formDisplay, "creativeDisplay");
                        break;
                    case "crea_native":
                        if(!await this.validFileNative()) { this.errorValid = true; return; };
                        this.errorValid = false;
                        let formNative = await this.getFormDataNative();
                        await this.submitCreative(formNative, "creativeNative");
                        break;
                    case "crea_video":
                        if(!await this.validFileVideo()) { this.errorValid = true; return; };
                        this.errorValid = false;
                        let formVideo = await this.getFormDataVideo();
                        await this.submitCreative(formVideo, "creativeVideo");
                        break;
                }

            } catch (error) {
                this.clearRules();
            }
            
        },

        async submitCreative(form: any, key: string){
            let params = {
                key: key,
                data: form,
                redirect: "",
                id: this.isEdit ? this.creative.id : undefined
            }
			await this.setLoadingData(this.isEdit ? TypeLoading.update : TypeLoading.create);
			
            if(this.isEdit){
                await this.updateCreative(params);
            }else{
                await this.createCreative(params);
            }
            await this.setLoadingData();
            this.$emit("set-table-active");
        },

        async parseEntity(entity: any){
            this.creative.id = entity.id;
            this.creative.type_creative = entity.creative_type_id;
            this.creative.name = entity.creative_name;
            this.creative.title_text = entity.creative_title_text;
            this.creative.body_text = entity.creative_body_text;
            this.creative.call_to_action = entity.creative_call_to_action;
            this.creative.size = await parseArr(entity.creative_size_id);
            this.creative.aspect_ratio = await parseArr(entity.creative_aspect_ratio_id);
            this.creative.imagen_display = await parseData(entity.creative_imagen_display);
            this.creative.imagen_native = await parseData(entity.creative_image_native);
            this.creative.imagen_video = await parseData(entity.creative_end_card_image);
            this.creative.icons = await parseData(entity.creative_icon);
            this.creative.vid = await parseData(entity.creative_video);
        },

    },
    watch:{}
});