import { isEmpty } from "lodash";

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export async function parseImgJson(params:string) {
    let v = JSON.parse(params);
    return v;
}

export async function parseData(img: string){
    if(isEmpty(img)) return [];
    var v = JSON.parse("[" + img + "]")[0];
    let source = new Array();
    if(Array.isArray(v)){
        source = v;
    }else{
        source.push(v);
    }
    let response = new Array();
    source.forEach((s: (any)) =>{
        response.push(s);
    });
    return response;
}

export async function parseArr(params: String){
    if(isEmpty(params)) return [];
    var v = JSON.parse("[" + params + "]")[0];
    let source = new Array();
    if(Array.isArray(v)){
        source = v;
    }else{
        source.push(v);
    }
    let response =  new Array();
    source.forEach((s: (any)) =>{
        response.push(Number(s));
    });
    return response;
}

export async function parseFile(file: any){
    let source = {
        file_name: "",
        file_url: "",
        thumbnail_url: "",
        file: file
    };
    let response = await toBase64(file);
    source.file_url = String(response);
    source.thumbnail_url = String(response);
    source.file_name = file.name;
    return source;
}